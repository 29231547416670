.bg-graylight {
  background-color: #f5f5f5;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-14 {
  font-size: 14px;
}

.connect-btn {
  line-height: 1 !important;
  font-size: 14px;
  font-weight: 700;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.lh-1 {
  line-height: 1 !important;
}

/* .allgives {
  box-shadow: 0px 0px 12px rgba(49, 49, 49, 0.2);
} */

.rounded {
  border-radius: 0.25rem !important;
}

.input-bg {
  background-color: #e9e9ed;
  height: 40px;
  padding-left: 12px !important;
}

.gives-icon {
  min-width: 16px;
  max-width: 16px;
  width: 16px;
}

/* .gives-edit-delete{

} */

.gives-edit-delete button svg {
  font-size: 13px;
}
.add-dropdown {
  padding-left: 12px !important;
  height: 40px;
  color: #919191;
}

.userdeskbord {
  margin-top: -2px;
  width: 22rem;
  z-index: 9;
  position: relative;
}

@media screen and (max-width: 390px) {
  .userdeskbord {
    width: 18rem;
  }
  .w-80 {
    width: 16rem !important;
  }
  .h-80 {
    height: 18rem !important;
  }
  .h-32 {
    height: 7rem !important;
  }
  .h-52 {
    height: 10rem !important;
  }
  .h-40 {
    height: 8rem !important;
  }
}

.chapter-dashboard {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
}
.chapter-call {
  flex: 0 0 auto;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
}

.chapter-call > div {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  height: 100%;
}
.leaderboar-animation {
  position: relative;
  overflow: hidden;
}

.ripple-effect-section .ripple-effect {
  width: 100%;
  height: calc(100% + 0px);
  position: absolute;
  left: unset;
  right: unset;
  top: unset;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* padding-top: 10%; */
}
.ripple-effect-section .ripple-effect .drop {
  height: 200px;
  width: 200px;
  /* background-color: transparent;
  position: absolute;
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
  transform: none; */
}
.ripple-effect-section .ripple-effect .drop-line {
  content: "";
  display: block;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  height: 200px;
  width: 200px;
  left: unset;
  top: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  opacity: 0;
}
.ripple-effect .drop-line:nth-child(1) {
  animation: rippless 12s linear 1s infinite;
}
.ripple-effect .drop-line:nth-child(2) {
  animation: rippless 12s linear 2s infinite;
}
.ripple-effect .drop-line:nth-child(3) {
  animation: rippless 12s linear 3s infinite;
}
.ripple-effect .drop-line:nth-child(4) {
  animation: rippless 12s linear 4s infinite;
}
.ripple-effect .drop-line:nth-child(5) {
  animation: rippless 12s linear 5s infinite;
}
.ripple-effect .drop-line:nth-child(6) {
  animation: rippless 12s linear 6s infinite;
}
.ripple-effect .drop-line:nth-child(7) {
  animation: rippless 12s linear 7s infinite;
}
.ripple-effect .drop-line:nth-child(8) {
  animation: rippless 12s linear 8s infinite;
}
.ripple-effect .drop-line:nth-child(9) {
  animation: rippless 12s linear 9s infinite;
}
.ripple-effect .drop-line:nth-child(10) {
  animation: rippless 12s linear 10s infinite;
}
.ripple-effect .drop-line:nth-child(11) {
  animation: rippless 12s linear 11s infinite;
}
.ripple-effect .drop-line:nth-child(12) {
  animation: rippless 12s linear 12s infinite;
}
.ripple-effect .drop-line:nth-child(13) {
  animation: rippless 12s linear 13s infinite;
}
.ripple-effect .drop-line:nth-child(14) {
  animation: rippless 12s linear 14s infinite;
}
.ripple-effect .drop-line:nth-child(15) {
  animation: rippless 12s linear 15s infinite;
}
.ripple-effect .drop-line:nth-child(16) {
  animation: rippless 12s linear 16s infinite;
}
.ripple-effect .drop-line:nth-child(17) {
  animation: rippless 12s linear 17s infinite;
}
.ripple-effect .drop-line:nth-child(18) {
  animation: rippless 12s linear 18s infinite;
  -webkit-animation: rippless 12s linear 18s infinite;
}
.ripple-effect .drop-line:nth-child(19) {
  animation: rippless 12s linear 19s infinite;
}
.ripple-effect .drop-line:nth-child(20) {
  animation: rippless 12s linear 20s infinite;
}
@keyframes rippless {
  0% {
    transform: scale(0);
    opacity: 0.2;
  }
  50% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0.2;
  }
}

.leaderboar-animation .bg-contain.bg-blend-overlay {
  position: relative;
  z-index: 2;
  background: transparent !important;
}

/* .ripple-effect-section {
	position: relative;
	z-index: 1;
} */

.contant-bg {
  background: #534b9d;
}

.weekly-btn {
  position: relative;
  z-index: 9;
}

.lastweek-btn {
  position: relative;
  z-index: 9;
}

.coppyright {
  background-color: #928cd2;
  text-align: center;
  padding: 12px;
  color: #fff;
}
/* .leaderboar-animation .coppyright{
  margin-bottom: -20px;
  margin-left: -20px;
  margin-right: -20px;
} */
.coppyright a {
  font-weight: 600;
  text-decoration: underline;
}

@media screen and (max-width: 1199px) {
  /* .chapter-dashboard {
	
    margin-left: -8px;
    margin-right: -8px;
  } */
  .chapter-call {
    padding-left: 8px;
    padding-right: 8px;
  }
  .chapter-call > div button {
    padding: 6px 10px !important;
  }
  .chapter-call > div h2 {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 991px) {
  .chapter-call {
    flex: 0 0 auto;
    width: 50%;
    margin-bottom: 15px;
  }
  .contant-bg h1 {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 479px) {
  .chapter-call {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 575.98px) {
  .ripple-effect-section .ripple-effect {
    height: 100%;
    bottom: 0px;
  }
  .ripple-effect-section .ripple-effect .drop {
    height: 120px;
    width: 120px;
  }
  .ripple-effect-section .ripple-effect .drop-line {
    height: 120px;
    width: 120px;
  }
}
